import React from "react";
import { Link } from "react-router-dom";

function Pagehead(props) {
    return (
        <>
            <header className="page-header wow fadeInUp headmarg" data-wow-delay="0.5s" style={{ backgroundImage: 'url(../img/BG.jpg)', paddingTop: 'initial', maxHeight: '60vh', filter: "saturate(0.5)" }}>
                <div className="container servi-mid">
                    <h2>{props.headtitle}</h2>
                    <div className="bosluk3"></div>
                    <p><Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/' style={{ color: 'white', textDecoration: 'none' }}>Home</Link> <i className="flaticon-right-chevron"></i> {props.page}</p>
                </div>
            </header>
        </>
    )
}

export default Pagehead;