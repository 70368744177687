import { Link } from "react-router-dom";
import React from "react";
import CountUp from 'react-countup';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pagehead from "./Pagehead";


function About() {
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 350,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //     ]
    // };

    // const phone = {
    //     color: 'white',
    //     textaline: 'center',
    //     width: '100%',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     gap: '18px'
    // }
    // const call = {
    //     padding: '6px 0 0'
    // }


    return (
        <>

            <Pagehead headtitle="About Us" page="About us" />


            <section className="services-top">
                <div className="h-yazi-ortalama h-yazi-margin-orta-3">
                    <h2 className="h2-baslik-hizmetler-2 wow fadeInUp" data-wow-delay="0.3s">Who Are We <strong
                        style={{ fontSize: '5rem', color: '#1a1a1a' }}> ?</strong> </h2>
                </div>
                <div className="bosluk3"></div>
                <div className="tablo">
                    <div className="tablo--1-ve-2 wow zoomInStable" data-wow-delay="0.4s">
                        <div className="galeri" data-tilt>
                            <img src="img/interior-design-about.png" alt="interior design about"
                                className="galeri__gorsel galeri__gorsel--3 zimage" />
                        </div>
                    </div>
                    <div className="tablo--1-ve-3 wow fadeInRight" data-wow-delay="0.5s">
                        <h2 className="h2-baslik-anasayfa-ozel1 wow fadeInRight" data-wow-delay="0.6s">New Generation
                            <strong><br />Modern Designs</strong> </h2>
                        <div className="bosluk333"></div>
                        <p className="paragraf wow fadeInRight" data-wow-delay="0.7s">
                            We are in time and we catch what the age brings by listening to the sound of time.
                            With our work through time, we produce long-term projects and realize the dreamed spaces.
                        </p>
                        <div className="bosluk333"></div>
                        <img className="divider" width="120" height="15" title="divider" alt="divider" src="img/divider.jpg" />
                        <div className="bosluk333"></div>
                        <h2 className="h2-baslik-anasayfa-ozel1 wow fadeInRight" data-wow-delay="0.8s">Trending
                            <strong>Designs</strong></h2>
                        <div className="bosluk333"></div>
                        <p className="paragraf wow fadeInRight" data-wow-delay="0.9s">
                            We are building long-term, new places in our project and application works that we started
                            with the understanding of quality service. We use state-of-the-art products with state-of-the-art
                            methods and mediate our customers to live in more comfortable, more comfortable and more stylish
                            spaces.
                        </p>
                        <div className="bosluk333"></div>
                        <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/contact' className="custom-button wow fadeInRight" data-wow-delay="1s">Contact Us →</Link>
                        <div className="bosluk3rh"></div>
                    </div>
                </div>
            </section>

            {/*        title       */}
            <section className="ozellika wow fadeInUp" data-wow-delay="0.3s" style={{ backgroundColor: 'black' }}>
                <div className="container">
                    <div className="row align-items-center no-gutters">
                        <div className="col-lg-12">
                            <div className="wow fadeInUp" data-wow-delay="0.4s">
                                <div className="boslukalt"></div>
                                <h2 className="h2-baslik-hizmetler-212 wow fadeInUp" data-wow-delay="0.5s"><strong>Our Team </strong> </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*       Our Team      */}
            <section className="team-section">
                <div className="container">
                    <div className="row">
                        <div className="col-3"></div>

                        <div className="row">
                            <div className="col-xl-4 team-head-grid">
                                <div className="team-head-shbox wow reveal-effect" data-wow-delay=".1s">
                                    <img src="/projects/shivlal.jpg" className="team-head-sh" alt="Shivlal" />
                                </div>
                            </div>
                            <div className="col-xl-7 team-desc-sh">
                                <h2 className="h2-baslik-anasayfa-ozel1 wow fadeInRight founder_title" data-wow-delay="0.6s">Founder Chairman
                                    <strong><br />Shivlal Prajapati</strong> </h2>
                                <div className="bosluk333"></div>
                                <p className="paragraf wow fadeInRight" data-wow-delay="0.7s">
                                    Shivlal Prajapati - The Creator of Shiv Builders, established a commanding position in the commercial capital of
                                    the state of Gujarat by deciding to diversify from stock broking handles. He also looks after the foundational
                                    responsibilities of the business
                                </p>
                                <div className="bosluk333"></div>
                                <img className="divider" width="120" height="15" title="divider" alt="divider" src="img/divider.jpg" />
                                <div className="bosluk333"></div>
                                <h2 className="h2-baslik-anasayfa-ozel1 wow fadeInRight founder_title" data-wow-delay="0.8s"><strong>Shiv Builders</strong></h2>
                                <div className="bosluk333"></div>
                                <p className="paragraf wow fadeInRight" data-wow-delay="0.9s">
                                    Since 1980, Shiv Builders We are building long-term, new places in our project and application works that we started
                                    with the understanding of quality service. We use state-of-the-art products with state-of-the-art
                                    methods and mediate our customers to live in more comfortable, more comfortable and more stylish
                                    spaces.
                                </p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="swiper-slide">
                                        <div className="class-box">
                                            <div className="services-kutu2 wow fadeInLeft" data-wow-delay="0.6s" style={{ cursor: 'pointer' }}>
                                                <div className="member-box wow reveal-effect">
                                                    <figure> <img src="/projects/harishbhai.jpeg" alt="Harish Bhai" />
                                                        <figcaption>
                                                            <h6>Harish Prajapati</h6>
                                                            <p className="paragraf-sol-beyaz-orta">Architect</p>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="swiper-slide">
                                        <div className="class-box">
                                            <div className="services-kutu2 wow fadeInLeft" data-wow-delay="0.6s" style={{ cursor: 'pointer' }}>
                                                <div className="member-box wow reveal-effect">
                                                    <figure> <img src="/projects/amrut.jpeg" alt="Amrut Bhai" />
                                                        <figcaption>
                                                            <h6>Amrut Prajapati</h6>
                                                            <p className="paragraf-sol-beyaz-orta">Architect</p>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="swiper-slide">
                                        <div className="class-box">
                                            <div className="services-kutu2 wow fadeInLeft" data-wow-delay="0.6s" style={{ cursor: 'pointer' }}>
                                                <div className="member-box wow reveal-effect">
                                                    <figure> <img src="/projects/ayush.jpeg" alt="Ayush" />
                                                        <figcaption>
                                                            <h6>Ayush Prajapati</h6>
                                                            <p className="paragraf-sol-beyaz-orta">Civil Engineer</p>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-12">
                            <Slider {...settings}>

                                <div className="swiper-slide">
                                    <div className="class-box">
                                        <div className="services-kutu2 wow fadeInLeft" data-wow-delay="0.6s" style={{ cursor: 'pointer' }}>
                                            <div className="member-box wow reveal-effect">
                                                <figure> <img src="/projects/harishbhai.jpeg" alt="Image" />
                                                    <figcaption>
                                                        <h6>Harish Prajapati</h6>
                                                        <p className="paragraf-sol-beyaz-orta">Architect</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="class-box">
                                        <div className="services-kutu2 wow fadeInLeft" data-wow-delay="0.6s" style={{ cursor: 'pointer' }}>
                                            <div className="member-box wow reveal-effect">
                                                <figure> <img src="/projects/amrut.jpeg" alt="Image" />
                                                    <figcaption>
                                                        <h6>Amrut Prajapati</h6>
                                                        <p className="paragraf-sol-beyaz-orta">Architect</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="class-box">
                                        <div className="services-kutu2 wow fadeInLeft" data-wow-delay="0.6s" style={{ cursor: 'pointer' }}>
                                            <div className="member-box wow reveal-effect">
                                                <figure> <img src="/projects/ayush.jpeg" alt="Image" />
                                                    <figcaption>
                                                        <h6>Ayush Prajapati</h6>
                                                        <p className="paragraf-sol-beyaz-orta">Civil Engineer</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div> */}

                    </div>
                </div>
            </section>

            {/*        Count         */}
            <section className="content-section" data-background="#fafafa" style={{ backgroundColor: 'white' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0.6s">
                            <div className="icon"><i className="flaticon-group"></i></div>
                            <div className="counter-box wow fade">
                                <CountUp className="odometer"
                                    start={0}
                                    end={214}
                                    duration={3}
                                    separator=""
                                    // decimals={4}
                                    decimal=","
                                ></CountUp>
                                <h6>Happy Clients</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInRight" data-wow-delay="0.7s">
                            <div className="icon"><i className="flaticon-badge"></i></div>
                            <div className="counter-box wow fade">
                                <CountUp className="odometer"
                                    start={0}
                                    end={268}
                                    duration={3}
                                    separator=""
                                    // decimals={4}
                                    decimal=","
                                ></CountUp>
                                <h6>Finished Projects</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInRight" data-wow-delay="0.8s">
                            <div className="icon"><i className="flaticon-helmet"></i></div>
                            <div className="counter-box wow fade">
                                <CountUp className="odometer"
                                    start={0}
                                    end={18}
                                    duration={5}
                                    separator=""
                                    // decimals={4}
                                    decimal=","
                                ></CountUp>
                                <h6>Ongoing Projects</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;