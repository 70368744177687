import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Pagehead from "./Pagehead";
import { Link } from 'react-router-dom';

function Project() {
    let Architecture = [
        { img: '/projects/Interioe/Office/wood 1.png', id: 1, title: 'Office' },
        { img: '/projects/Interioe/living room/lr2.jpeg', id: 2, title: 'Living Room' },
        { img: '/projects/Interioe/bedroom/home.jpeg', id: 3, title: 'Bedroom' },
        { img: '/projects/Interioe/kitchen/home.jpeg', id: 4, title: 'Kitchen' },
        { img: '/projects/Interioe/Bathroom/bt5.jpeg', id: 5, title: 'Bathroom' },
        { img: '/projects/Interioe/Pan Palor/home.jpg', id: 6, title: 'Pan Parlor' },
        { img: '/projects/Interioe/Saloon/home.jpg', id: 7, title: 'Saloon' }
    ]
    let Furniture = [
        { id: 11, img: '/projects/2- Jaykishan Bhai/full3.jpg', title: 'Jaymala Ahmedabad' },
        { id: 12, img: '/projects/3- Mohan Nopaji/fewc.jpg', title: 'Isanpur Ahmedabad' },
        { id: 13, img: '/projects/7-ROYAL/new.jpg', title: 'Heritage' },
        { id: 14, img: '/projects/4-Prakash mali/Waiting room/Malani Waiting room 3.jpg', title: 'Malani Organic Food' },
        { id: 15, img: '/projects/6-vinod balotra/lr 1 copy.jpg', title: 'Balotra Rajasthan' }
    ]
    let Architectural = [
        { id: 21, img: '/projects/Architectrual/w4re.png', title: 'Project 1' },
        { id: 22, img: '/projects/Architectrual/rew.png', title: 'Project 2' }
    ]
    let degree = [{ img: '/projects/360deg.jpg', id: 4, title: 'Project 4', linkdeg: '/' }]

    let specifications = [
        { title: 'Structures', description: "R.C.C. frame strucure as per Structural Engineer's design." },
        { title: 'Interior Finish', description: "Mala plaster with wall care putty." },
        { title: 'Exterior Finidh', description: "Gutki or sand faced plaster with weather cast pint." },
        { title: 'Flooring', description: "Vitrified tite flooring" },
        { title: 'Kitchen', description: "Granite Modular Platform with SS Sinks, Ceramic up to lintel level." },
        { title: 'Doors', description: "Decorative Main door and all flush door with double side laminaters." },
        { title: 'Windows', description: "Window shutters from Aluminum section with powder coating" },
        { title: 'Electrification', description: "ISI Copper wiring with sufficient electrical points incl. T.V., A.C., & Geyser in toilets with modular type switches." },
        { title: 'Water Supply', description: "24 Hours water supply from tube well pupm - overhead tank." },
        { title: 'Elevator', description: "Elevator of standard make with capacity of 4 persons for easy access in each tower." },
        { title: 'Parking', description: "Adequate parking facilities with paver block." },
        { title: 'Plumbing', description: "CPVC Pipe and brass C.P. fixturess." },
        { title: 'Sanitory', description: "PVC Pipes and brabded fittings." },
    ]
    let condition = [
        { terms: "Internal changes if any shall be done at the cost of the owner of the flat or store without affecting the elevation." },
        { terms: "Full payment shall be made before the possession is given." },
        { terms: "Maintenance deposit shall be paid at the time of taking possession." },
        { terms: "Muncipal taxes shall be borne by the member from our date of completion. " },
        { terms: "Developers reserve the right to change the plan if required." },
        { terms: "Subject to Anand Jurisdiction." },
        { terms: "Additional documnentation charges such as Stamp Duty / Registration / GEB connections  / Service Tax / legal documents ( as per new bylaw's / Jantri/ Revenue Department ). and other relative expenditure is to be borne by the member." },
        { terms: "The brochure is just to give the idea in general and shall not be trated as legal document." },
        { terms: "In case of cancellation of the booking 15% of the cost of the Flat or Shop will be deducted and the balance amount shall be refunded after re booking of the same flat." },
        { terms: "15% interest Shall be charged for the delayed payment." }
    ]
    let payment = [
        { payterm: "30%  at the time of booking | 20% at the time of 1st floor slab level" },
        { payterm: "20%  at the time of 3rd floor slab level | 15% at the time of terrace slab level" },
        { payterm: "10%  at the time of plaster & flooring | 05% at the time of possession" }
    ]
    return (
        <>

            <Pagehead headtitle="Projects" page="Projects" />

            <main>
                {/* <!--Project Alanı--> */}
                <div className="bosluk3"></div>
                <div className="h-yazi-ortalama h-yazi-margin-orta-4 wow fadeInUp" data-wow-delay="0.3s">
                    <h2 className="h2-baslik-hizmetler-2"> <strong>Projects</strong></h2>
                </div>
                <div className="tabloozellik">
                    <div className="component-systemTabs">
                        <div className="tabs-container">
                            <Tabs >
                                <TabList style={{ textAlign: 'center', borderBottom: 'none' }}>
                                    <Tab class="tab-link current" style={{ float: 'none', fontSize: '18px', color: 'black' }}><li >Shivalay Heights</li></Tab>
                                    <Tab class="tab-link current" style={{ float: 'none', fontSize: '18px', color: 'black' }}><li >Interior Architecture Project</li></Tab>
                                    <Tab class="tab-link current" style={{ float: 'none', fontSize: '18px', color: 'black' }}><li >Full Fledged Project</li></Tab>
                                    <Tab class="tab-link current" style={{ float: 'none', fontSize: '18px', color: 'black' }}><li >Architectural Project</li></Tab>
                                    <Tab class="tab-link current" style={{ float: 'none', fontSize: '18px', color: 'black' }}><li >360° View</li></Tab>
                                </TabList>

                                <TabPanel>

                                    {/*          shivalay height             */}
                                    <div className='container shivalay-heights-main'>
                                        <div className='row'>
                                            <div className='col-md-8 reveal-effect masker wow' data-wow-delay="0.9s">
                                                <img src='/projects/shivalay heights/sv2.png' className='shivalaypho' />
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="bosluk3"></div>
                                                <h2 className="h2-baslik-anasayfa-ozel-services wow fade founder_title"> Shivalay Heights </h2>
                                                <div className="bosluk333"></div>
                                                <img className="divider" width="120" height="15" title="divider" alt="divider" src="img/divider.jpg" />
                                                <p className="paragraf wow fadeInRight" data-wow-delay="0.7s">Shivalay Heights, where elevated living meets architectural
                                                    finesse. Nestled in Shivalay Heights, Nandalaya Road, Bakrol, Nana Bazaar, Vallabh Vidyanagar, Anand, Gujarat 388120, this avant-garde
                                                    residence redefines luxury, offering meticulously designed spaces and an indulgent lifestyle. Each unit within our 4 floors
                                                    exudes opulence, blending modernity with comfort seamlessly. Strategically positioned near Anand, Shivalay Heights
                                                    ensures unparalleled convenience. Experience a harmonious community and sophisticated living. Embrace the pinnacle of modernity at
                                                    Shivalay Heights, where every corner reflects excellence. Contact us now to embark on your journey to luxurious living.</p>
                                            </div>
                                        </div>

                                        <div className='row shivalay'>
                                            <div className='col-md-4'>
                                                <div className="bosluk3"></div>
                                                <h2 className="h2-baslik-anasayfa-ozel-services wow fade founder_title"> Shivalay Heights </h2>
                                                <div className="bosluk333"></div>
                                                <img className="divider" width="120" height="15" title="divider" alt="divider" src="img/divider.jpg" />
                                                <div className='row shivalay-details wow fadeInLeft' data-wow-delay="0.7s">
                                                    <div className='col-1'>
                                                        <p className="shivalay-details" ><i class="fa-solid fa-hotel"></i></p>
                                                        <p className="shivalay-details" ><i class="fa-solid fa-store"></i></p>
                                                        <p className="shivalay-details" ><i class="fa-solid fa-square-parking"></i></p>
                                                        <p className="shivalay-details" ><i class="fa-solid fa-fire-flame-curved"></i></p>
                                                        <p className="shivalay-details" ><i class="fa-solid fa-cable-car"></i></p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <p className="shivalay-details" >10 Flats</p>
                                                        <p className="shivalay-details" >12 Shop</p>
                                                        <p className="shivalay-details" >1 Parking </p>
                                                        <p className="shivalay-details" >Fire Safety </p>
                                                        <p className="shivalay-details" >1 Lift</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-8 reveal-effect masker wow' data-wow-delay="0.7s">
                                                <img src='/projects/shivalay heights/sv3.png' className='shivalaypho' />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-8 reveal-effect masker wow' data-wow-delay="0.9s">
                                                <img src='/projects/shivalay heights/sv4.png' className='shivalaypho' />
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="bosluk3"></div>
                                                <h2 className="h2-baslik-anasayfa-ozel-services wow fade founder_title"> Shivalay Heights </h2>
                                                <div className="bosluk333"></div>
                                                <img className="divider" width="120" height="15" title="divider" alt="divider" src="img/divider.jpg" />
                                                <div className='row shivalay-details wow fadeInRight' data-wow-delay="0.7s">
                                                    <div className='col-1'>
                                                        <p className="shivalay-details" ><i class="fa-solid fa-hotel"></i></p>
                                                        <p className="shivalay-details" ><i class="fa-solid fa-store"></i></p>
                                                        <p className="shivalay-details" ><i class="fa-solid fa-square-parking"></i></p>
                                                        <p className="shivalay-details" ><i class="fa-solid fa-fire-flame-curved"></i></p>
                                                        <p className="shivalay-details" ><i class="fa-solid fa-cable-car"></i></p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <p className="shivalay-details" >10 Flats</p>
                                                        <p className="shivalay-details" >12 Shop</p>
                                                        <p className="shivalay-details" >1 Parking </p>
                                                        <p className="shivalay-details" >Fire Safety </p>
                                                        <p className="shivalay-details" >1 Lift</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row shivalay'>
                                            <div className='col-md-5'>
                                                <div className="bosluk3"></div>
                                                <h2 className="h2-baslik-anasayfa-ozel-services wow fade founder_title"> Specifications </h2>
                                                <div className="bosluk333"></div>
                                                <img className="divider" width="120" height="15" title="divider" alt="divider" src="img/divider.jpg" />
                                                <div className='row wow fadeInLeft' data-wow-delay="0.7s" style={{ color: 'black' }}>
                                                    {specifications.map((detail, index) => (
                                                        <div className='row' key={index}>
                                                            <div className='col-4 specific'><p className="paragraf"><b>{detail.title} :</b></p></div>
                                                            <div className='col-8 specific'><p className="paragraf">{detail.description}</p></div>
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                            <div className='col-md-7 reveal-effect masker wow' data-wow-delay="0.7s">
                                                <img src='/projects/shivalay heights/sv5.png' className='shivalaypho' />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-7 reveal-effect masker wow' data-wow-delay="0.9s">
                                                <img src='/projects/shivalay heights/sv6.png' className='shivalaypho' />
                                            </div>
                                            <div className='col-md-5'>
                                                <div className="bosluk3"></div>
                                                <h2 className="h2-baslik-anasayfa-ozel-services wow fade founder_title">Terms & Condition </h2>
                                                <div className="bosluk333"></div>
                                                <img className="divider" width="120" height="15" title="divider" alt="divider" src="img/divider.jpg" />
                                                <div className='row wow fadeInLeft' data-wow-delay="0.7s" style={{ color: 'black' }}>
                                                    {condition.map((detail, index) => (
                                                        <div className='row' key={index}>
                                                            <div className='col-1 specific'><p className="paragraf" style={{ textAlign: 'center' }}><i class="fa-solid fa-caret-right"></i></p></div>
                                                            <div className='col-11 specific'><p className="paragraf">{detail.terms}</p></div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row shivalay'>
                                            <div className='col-md-4'>
                                                <div className="bosluk3"></div>
                                                <h2 className="h2-baslik-anasayfa-ozel-services wow fade founder_title">Payment Terms</h2>
                                                <div className="bosluk333"></div>
                                                <img className="divider" width="120" height="15" title="divider" alt="divider" src="img/divider.jpg" />
                                                <div className='row wow fadeInLeft' data-wow-delay="0.7s" style={{ color: 'black' }}>
                                                    {payment.map((detail, index) => (
                                                        <div className='row' key={index}>
                                                            <div className='col-1 specific'><p className="paragraf" style={{ textAlign: 'center' }}><i class="fa-solid fa-caret-right"></i></p></div>
                                                            <div className='col-11 specific'><p className="paragraf">{detail.payterm}</p></div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='col-md-8 reveal-effect masker wow' data-wow-delay="0.9s">
                                                <img src='/projects/shivalay heights/sv7.png' className='shivalaypho' />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-6 shivalay-map-main reveal-effect masker wow' data-wow-delay="0.9s">
                                                <iframe className='shivalay-map' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3684.601699530165!2d72.92750092479034!3d22.556588422875894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDMzJzI0LjAiTiA3MsKwNTUnMzkuMCJF!5e0!3m2!1sen!2sin!4v1703924396562!5m2!1sen!2sin" width="400" height="400" style={{ border: '3px solid black', borderRadius: '50%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="bosluk3"></div>
                                                <h2 className="h2-baslik-anasayfa-ozel-services wow fade founder_title"> Location </h2>
                                                <div className="bosluk333"></div>
                                                <img className="divider" width="120" height="15" title="divider" alt="divider" src="img/divider.jpg" />
                                                <Link to={'https://maps.app.goo.gl/uJHGpHnNbxwFxxAZ8'} target='blank'><p className="paragraf wow fadeInRight" data-wow-delay="0.7s"><b>Shivalay Heights, Nandalaya Road, Bakrol, Nana Bazaar, Vallabh Vidyanagar, Anand, Gujarat 388120</b></p></Link>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="cards">
                                        {Architecture.map((card, index) => {
                                            return <div key={index} className="card project-image reveal-effect masker wow">
                                                <img src={card.img} alt="Project" />
                                                <div className="cardContent">
                                                    <h2>{card.title}</h2>
                                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to={`/prjectdetails/${card.id}`} style={{ textDecoration: 'none' }}><button >View</button></Link>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="cards">
                                        {Furniture.map((card, index) => {
                                            return <div key={index} className="card project-image reveal-effect masker wow">
                                                <img src={card.img} alt="Project" />
                                                <div className="cardContent">
                                                    <h2>{card.title}</h2>
                                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to={`/prjectdetails/${card.id}`} style={{ textDecoration: 'none' }}><button >View</button></Link>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="cards">
                                        {Architectural.map((card, index) => {
                                            return <div key={index} className="card project-image reveal-effect masker wow">
                                                <img src={card.img} alt="Project" />
                                                <div className="cardContent">
                                                    <h2>{card.title}</h2>
                                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to={`/projectdetailsach/${card.id}`} style={{ textDecoration: 'none' }}><button >View</button></Link>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="cards">
                                        {degree.map((card, index) => {
                                            return <div key={index} className="card project-image reveal-effect masker wow">
                                                <img src={card.img} alt="Project" />
                                                <div className="cardContent">
                                                    <h2>{card.title}</h2>
                                                    <Link to={'https://kuula.co/profile/Ayushprajapati79/collections'} target='blank' style={{ textDecoration: 'none' }}><button >360° View</button></Link>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </TabPanel>


                            </Tabs>
                        </div>
                    </div>
                </div>
            </main >
        </>
    )
}

export default Project;