

import React from 'react'
import Pagehead from './Pagehead'
import { Link, useParams } from "react-router-dom";
import '../css/style.css'

function Projectdetailsarch() {

    const { id } = useParams();
    var project = []
    var head = []


    //            Architectural
    if (id == 21) {
        project = [{ img: "/projects/Architectrual/01a.png" }, { img: "/projects/Architectrual/1 copyy.jpg" }, { img: "/projects/Architectrual/1.jpg" }, { img: "/projects/Architectrual/1st.jpg" }, { img: "/projects/Architectrual/2 A.png" }, { img: "/projects/Architectrual/2 copyy.jpg" }, { img: "/projects/Architectrual/2.jpg" }, { img: "/projects/Architectrual/22 copy.jpg" }, { img: "/projects/Architectrual/22.jpg" }, { img: "/projects/Architectrual/66.jpg" }, { img: "/projects/Architectrual/4568.jpg" }]
    } else if (id == 22) {
        project = [{ img: "/projects/Architectrual/dfgh.jpg" }, { img: "/projects/Architectrual/fwff.jpg" }, { img: "/projects/Architectrual/g1 copy.jpg" }, { img: "/projects/Architectrual/ger.jpg" }, { img: "/projects/Architectrual/gv copy.jpg" }, { img: "/projects/Architectrual/Kishorbhai soni Chandrai 2.jpg" }, { img: "/projects/Architectrual/Kishorbhai soni Chandrai 3.jpg" }, { img: "/projects/Architectrual/Rajendra Sharma 1.jpg" }, { img: "/projects/Architectrual/Rameshkaka 1.jpg" }, { img: "/projects/Architectrual/rew.png" }, { img: "/projects/Architectrual/w4re.png" }]
    }

    return (
        <>

            <header className="page-header wow fadeInUp" data-wow-delay="0.5s" style={{ backgroundImage: 'url(../img/BG.jpg)', paddingTop: 'initial', marginTop: '90px', maxHeight: '60vh', filter: "saturate(0.5)" }}>
                <div className="container servi-mid">
                    <h2>Project Details</h2>
                    <div className="bosluk3"></div>
                    <p><Link to='/' style={{ color: 'white', textDecoration: 'none' }}>Home</Link> <i className="flaticon-right-chevron" /><Link to='/project' style={{ color: 'white', textDecoration: 'none' }}>Project</Link> <i className="flaticon-right-chevron" /> Project Details</p>
                </div>
            </header>



            <section className="hizmetler-detay-sayfasi-alani architectural-data ">
                <div className="h-yazi-ozel h-yazi-margin-ozel">
                </div>
                <div className="h-yazi-ortalama h-yazi-margin-orta-3 wow fadeInUp" data-wow-delay="0.5s">
                    <h2 className="baslik-2 h-yazi-margin-kucuk gallery-pro"> Project Gallery </h2>
                </div>
                {/*     grid 6026 css colums    */}
                <div className="grid-container wow fadeInUp" style={{ columns: '3 200px' }} data-wow-delay="0.6s">
                    {project.map((photo, index) => {
                        return <div key={index} style={{ height: '45vh' }}>
                            <img className='grid-item grid-item-1' src={photo.img} alt="Galeri" />
                        </div>
                    })}
                </div>
            </section>

        </>
    )
}

export default Projectdetailsarch;