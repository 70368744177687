import { Link } from "react-router-dom";
import Pagehead from "./Pagehead";

function Contact() {
    return (
        <>
            <Pagehead headtitle="Contact" page="Contact" />

            <main>
                <section class="iletisim-icon-alani">
                    <div class="tablo">
                        <div className="tablo--1-ve-3 wow fadeInLeft" data-wow-delay="0.5s" >
                            <div class="ozellik-kutu-iletisim" style={{ cursor: 'pointer' }} data-tilt>
                                <div className="contact-profile"><img src="/projects/harishbhaipic.jpeg" alt="Image" /></div>
                                <h3 class="baslik-4 h-yazi-margin-kucuk-2 mt-4">Harish Prajapati</h3>
                                <Link to={'tel:9327006903'}><p class="ozellik-kutu-iletisim--yazi"> 9327006903</p></Link>
                                <Link to={'mailto:harish11.prajapati@gmail.com'}><p class="ozellik-kutu-iletisim--yazi">harish11.prajapati@gmail.com</p></Link>
                            </div>
                        </div>
                        <div className="tablo--1-ve-3 wow fadeIn" data-wow-delay="0.5s" >
                            <div class="ozellik-kutu-iletisim" style={{ cursor: 'pointer' }} data-tilt>
                                <div className="contact-profile"><img src="/projects/amrutpic.jpeg" alt="Image" /></div>
                                <h3 class="baslik-4 h-yazi-margin-kucuk-2 mt-4">Amrut Prajapati</h3>
                                <Link to={'tel:9998843177'}><p class="ozellik-kutu-iletisim--yazi"> 9998843177</p></Link>
                                <Link to={'mailto:amrut03shiv@gmail.com'}><p class="ozellik-kutu-iletisim--yazi">amrut03shiv@gmail.com</p></Link>
                            </div>
                        </div>
                        <div className="tablo--1-ve-3 wow fadeInRight" data-wow-delay="0.5s" >
                            <div class="ozellik-kutu-iletisim" style={{ cursor: 'pointer' }} data-tilt>
                                <div className="contact-profile"><img src="/projects/ayushpic.jpeg" alt="Image" /></div>
                                <h3 class="baslik-4 h-yazi-margin-kucuk-2 mt-4">Ayush Prajapati</h3>
                                <Link to={'tel:9081110595'}><p class="ozellik-kutu-iletisim--yazi"> 9081110595</p></Link>
                                <Link to={'mailto:ayushprajapati58@gmail.com'}><p class="ozellik-kutu-iletisim--yazi">ayushprajapati58@gmail.com</p></Link>
                            </div>
                        </div>
                    </div>
                </section>

            </main >
        </>
    )
}

export default Contact;