import { Link } from "react-router-dom";


function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4">
                            <div className="logo wow fadeInUp" data-wow-delay="0.3s"> <img src="/img/logo-footer.png" alt="Image" />
                            </div>
                            <div className="row wow fadeInUp">
                                <div className="col-2">
                                    <i className="flaticon-call iconpfooter" style={{ paddingTop: '2.5vh' }}></i>
                                </div>
                                <div className="col-10">
                                    <a href="tel:9327006903" className="foot-number">93270 06903</a><br />
                                    <a href="tel:9998843177" className="foot-number">99988 43177</a><br />
                                    <a href="tel:9081110595" className="foot-number">90811 10595</a>
                                </div>
                                <div className="col-2">
                                    <i className="flaticon-email iconpfooter"></i>
                                </div>
                                <div className="col-10">
                                    <a href="mailto:ayushprajapati58@gmail.com" target="blank" className="foot-number">ayushprajapati58@gmail.com</a>
                                </div>
                                <div className="col-2">
                                    <i className="flaticon-location iconpfooter"></i>
                                </div>
                                <div className="col-10">
                                    <a href="https://maps.app.goo.gl/8LpEgWYYuEnQ2KHa8" target="blank" className="foot-number">14 varaimata colony, Isanpur, Ahmedabad, Gujarat - 382443</a>
                                </div>
                            </div>

                            <div className="footer-info wow fadeInUp" data-wow-delay="0.4s">
                                <p></p>
                            </div>
                            <ul className="footer-social wow fadeInUp" data-wow-delay="0.5s">
                                <li><a href="https://www.facebook.com/" target="blank"><i className="fa-brands fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/shivbuilders_79?igsh=YzVkODRmOTdmMw==" target="blank"><i className="fa-brands fa-instagram"></i></a></li>
                            </ul>


                        </div>
                        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.6s">
                            <h6 className="widget-title">Shiv Builders</h6>
                            <p className="footerp">
                                We are in time and we catch what the age brings by listening to the sound of time.
                                With our work through time, we produce long-term projects and realize the dreamed spaces.
                            </p><br />

                            <Link onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} to={'/contact'} className="custom-button">CONTACT US</Link>
                        </div>
                        <div className="col-lg-2 offset-xl-1 col-sm-6 wow fadeInUp" data-wow-delay="0.8s">
                            <h6 className="widget-title">Quick Links</h6>
                            <ul className="footer-menu">
                                <li><Link onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} to='/'>Home</Link></li>
                                <li><Link onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} to='/about'>About Us</Link> </li>
                                <li><Link onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} to='/service'>Services</Link> </li>
                                <li><Link onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} to='/project'>Projects</Link></li>
                                <li><Link onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} to='/contact'>Contact</Link></li>
                            </ul>
                        </div>
                        <div className="col-12" >
                            <p className="copyright">© 2024 - Develop by Devang</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;