
import React from 'react'
import Pagehead from './Pagehead'
import { Link, useParams } from "react-router-dom";
import '../css/style.css'

function Projectdetails() {

    const { id } = useParams();
    var project = []
    var head = []

    //               Architecture
    if (id == 1) {
        project = [{ img: '/projects/Interioe/Office/wood 1.png' }, { img: '/projects/Interioe/Office/Office 11.png' }, { img: '/projects/Interioe/Office/office1.jpg' }, { img: '/projects/Interioe/Office/Office 2.png' }, { img: '/projects/Interioe/Office/office.jpg' }, { img: '/projects/Interioe/Office/office2.jpg' }]
        head = [{ headimg: '/projects/Interioe/Office/wood 1.png', headtitle: 'Interior Office Design', headdis: 'Design of office furniture in clear colors and tones' }]
    } else if (id == 2) {
        project = [{ img: '/projects/Interioe/living room/lr1.jpeg' }, { img: '/projects/Interioe/living room/lr2.jpeg' }, { img: '/projects/Interioe/living room/home.jpg' }, { img: '/projects/Interioe/living room/hall-21.jpg' }]
        head = [{ headimg: '/projects/Interioe/living room/lr1.jpeg', headtitle: 'Interior Living Room Design', headdis: 'Design of Living Room furniture in clear colors and tones' }]
    } else if (id == 3) {
        project = [{ img: '/projects/Interioe/bedroom/bd3.jpeg' }, { img: '/projects/Interioe/bedroom/bd8.jpeg' }, { img: '/projects/Interioe/bedroom/bd14.jpeg' }, { img: '/projects/Interioe/bedroom/bd13.jpeg' }, { img: '/projects/Interioe/bedroom/bd12.jpeg' }, { img: '/projects/Interioe/bedroom/bd10.jpeg' }, { img: '/projects/Interioe/bedroom/bd6.jpeg' }, { img: '/projects/Interioe/bedroom/bd15.jpeg' }, { img: '/projects/Interioe/bedroom/bd17.jpeg' }, { img: '/projects/Interioe/bedroom/bd18.jpeg' }, { img: '/projects/Interioe/bedroom/bd19.jpeg' }]
        head = [{ headimg: '/projects/Interioe/bedroom/bd16.jpeg', headtitle: 'Interior bedroom Design', headdis: 'Design of bedroom furniture in clear colors and tones' }]
    } else if (id == 4) {
        project = [{ img: '/projects/Interioe/kitchen/kt3.jpeg' }, { img: '/projects/Interioe/kitchen/kt1.jpeg' }, { img: '/projects/Interioe/kitchen/kt4.jpeg' }, { img: '/projects/Interioe/kitchen/kt8.jpeg' }, { img: '/projects/Interioe/kitchen/kt6.jpeg' }, { img: '/projects/Interioe/kitchen/kt9.jpeg' }, { img: '/projects/Interioe/kitchen/kt5.jpeg' }, { img: '/projects/Interioe/kitchen/home.jpeg' }]
        head = [{ headimg: '/projects/Interioe/kitchen/kt5.jpeg', headtitle: 'Interior Kitchen Design', headdis: 'Design of kitchen furniture in clear colors and tones' }]
    } else if (id == 5) {
        project = [{ img: '/projects/Interioe/Bathroom/bt2.jpeg' }, { img: '/projects/Interioe/Bathroom/btt1.png' }, { img: '/projects/Interioe/Bathroom/bt3.jpg' }, { img: '/projects/Interioe/Bathroom/bt14.jpg' }, { img: '/projects/Interioe/Bathroom/bt5.jpeg' }, { img: '/projects/Interioe/Bathroom/bt9.jpeg' }, { img: '/projects/Interioe/Bathroom/bt6.jpeg' }, { img: '/projects/Interioe/Bathroom/bt7.jpeg' }]
        head = [{ headimg: '/projects/Interioe/Bathroom/bt6.jpeg', headtitle: 'Interior Bathroom Design', headdis: 'Design of bathroom furniture in clear colors and tones' }]
    } else if (id == 6) {
        project = [{ img: '/projects/Interioe/Pan Palor/pp6.jpg' }, { img: '/projects/Interioe/Pan Palor/pp1.jpg' }, { img: '/projects/Interioe/Pan Palor/pp2.jpg' }, { img: '/projects/Interioe/Pan Palor/pp3.jpg' }, { img: '/projects/Interioe/Pan Palor/pp4.jpg' }, { img: '/projects/Interioe/Pan Palor/pp5.jpg' }, { img: '/projects/Interioe/Pan Palor/home.jpg' }]
        head = [{ headimg: '/projects/Interioe/Pan Palor/pp6.jpg', headtitle: 'Interior Pan Parlor Design', headdis: 'Design of pan parlor furniture in clear colors and tones' }]
    } else if (id == 7) {
        project = [{ img: '/projects/Interioe/Saloon/sl1.jpg' }, { img: '/projects/Interioe/Saloon/sl3.jpg' }, { img: '/projects/Interioe/Saloon/home.jpg' }, { img: '/projects/Interioe/Saloon/sl4.jpg' }, { img: '/projects/Interioe/Saloon/sl5.jpg' }, { img: '/projects/Interioe/Saloon/sl6.jpg' }, { img: '/projects/Interioe/Saloon/sl2.jpg' }]
        head = [{ headimg: '/projects/Interioe/Saloon/sl2.jpg', headtitle: 'Interior Saloon Design', headdis: 'Design of saloon furniture in clear colors and tones' }]
    }

    //             Furniture
    if (id == 11) {
        project = [{ img: "/projects/2- Jaykishan Bhai/full.jpg" }, { img: "/projects/2- Jaykishan Bhai/full1.jpg" }, { img: "/projects/2- Jaykishan Bhai/full2.jpg" }, { img: "/projects/2- Jaykishan Bhai/full3.jpg" }, { img: "/projects/2- Jaykishan Bhai/full4.jpg" }, { img: "/projects/2- Jaykishan Bhai/full5.jpg" }, { img: "/projects/2- Jaykishan Bhai/full6.jpg" }, { img: "/projects/2- Jaykishan Bhai/full7.jpg" }]
        head = [{ headimg: "/projects/2- Jaykishan Bhai/full4.jpg", headtitle: 'Jaymala Ahmedabad' }]
    } else if (id == 12) {
        project = [{ img: "/projects/3- Mohan Nopaji/jk.jpg" }, { img: "/projects/3- Mohan Nopaji/Mohanlal Nopaji 1.jpg" }, { img: "/projects/3- Mohan Nopaji/BEDROOM 2/4 copy.jpg" }, { img: "/projects/3- Mohan Nopaji/BEDROOM 2/dfghjk.jpg" }, { img: "/projects/3- Mohan Nopaji/BEDROOM 2/5 copy.jpg" }]
        head = [{ headimg: "/projects/3- Mohan Nopaji/fewc.jpg", headtitle: 'Interior Architecture Design' }]
    } else if (id == 13) {
        project = [{ img: "/projects/7-ROYAL/1.png" }, { img: "/projects/7-ROYAL/ewffw.jpg" }, { img: "/projects/7-ROYAL/54f5v.jpg" }, { img: "/projects/7-ROYAL/3.jpg" }, { img: "/projects/7-ROYAL/final 5.jpg" }, { img: "/projects/7-ROYAL/final day 2.jpg" }, { img: "/projects/7-ROYAL/final day 1.jpg" }, { img: "/projects/7-ROYAL/4.jpg" }]
        head = [{ headimg: "/projects/7-ROYAL/finalllllll.jpg", headtitle: 'Royal Heritage' }]
    } else if (id == 14) {
        project = [{ img: "/projects/4-Prakash mali/PM room/CEO Room 1.1.jpg" }, { img: "/projects/4-Prakash mali/PM room/CEO Room 1.jpg" }, { img: "/projects/4-Prakash mali/PM room/CEO Room 360 f.jpg" }, { img: "/projects/4-Prakash mali/Waiting room/Malani Waiting room 1.jpg" }, { img: "/projects/4-Prakash mali/Waiting room/Malani Waiting room 2.jpg" }]
        head = [{ headimg: "/projects/4-Prakash mali/Waiting room/Malani Waiting room 3.jpg", headtitle: 'Malani Organic Food' }]
    } else if (id == 15) {
        project = [{ img: "/projects/6-vinod balotra/bed g/1 copy.jpg" }, { img: "/projects/6-vinod balotra/bed g/2 copy.jpg" }, { img: "/projects/6-vinod balotra/bed g/11 copy.jpg" }, { img: "/projects/6-vinod balotra/bed g/44 copy.jpg" }, { img: "/projects/6-vinod balotra/bed g/3 copy.jpg" }, { img: "/projects/6-vinod balotra/11 copy.jpg" }, { img: "/projects/6-vinod balotra/33 copy.jpg" }, { img: "/projects/6-vinod balotra/lr 1 copy.jpg" }, { img: "/projects/6-vinod balotra/lr 2 copy.jpg" }, { img: "/projects/6-vinod balotra/v1 copy.jpg" }]
        head = [{ headimg: "/projects/6-vinod balotra/bed g/11 copy.jpg", headtitle: 'Balotra Rajasthan' }]
    }

    return (
        <>

            <header className="page-header wow fadeInUp" data-wow-delay="0.5s" style={{ backgroundImage: 'url(../img/BG.jpg)', paddingTop: 'initial', marginTop: '90px', maxHeight: '60vh', filter: "saturate(0.5)" }}>
                <div className="container servi-mid">
                    <h2>Project Details</h2>
                    <div className="bosluk3"></div>
                    <p><Link to='/' style={{ color: 'white', textDecoration: 'none' }}>Home</Link> <i className="flaticon-right-chevron" /><Link to='/project' style={{ color: 'white', textDecoration: 'none' }}>Project</Link> <i className="flaticon-right-chevron" /> Project Details</p>
                </div>
            </header>



            <section className="hizmetler-detay-sayfasi-alani">
                <div className="h-yazi-ozel h-yazi-margin-ozel">
                </div>
                {head.map((headdata, index) => {
                    return <div className="tablohizmetlerdetay" key={index}>
                        <div className="tablo--1-ve-2 wow zoomInDown" data-wow-delay="0.5s">
                            <div className="galeri" >
                                <img src={headdata.headimg} alt="Project Detail" className="galeri__gorsel galeri__gorsel--4" />
                            </div>
                        </div>
                        <div className="tablo--1-ve-2">
                            <div className="services-kutu1 wow fadeInRight" data-wow-delay="0.6s">
                                <div className="icon-project-detail"><i className="flaticon-handshake"></i></div>
                                <h3 className="baslik-2 h-yazi-margin-kucuk">Project Title</h3>
                                <p className="services-kutu1--yazi-p">{headdata.headtitle}</p>
                            </div>
                            <div className="bosluk-kaldir"></div>
                            <div className="services-kutu1 wow fadeInRight" data-wow-delay="0.7s">
                                <div className="icon-project-detail"><i className="flaticon-helmet"></i></div>
                                <h3 className="baslik-2 h-yazi-margin-kucuk">Project Detail</h3>
                                <p className="services-kutu1--yazi-p">{headdata.headdis}</p>
                                <div className="bosluk9"></div>
                            </div>
                        </div>
                    </div>
                })}
                <div className="h-yazi-ortalama h-yazi-margin-orta-3 wow fadeInUp" data-wow-delay="0.5s">
                    <h2 className="baslik-2 h-yazi-margin-kucuk"> Project Gallery </h2>
                </div>
                {/*     grid 6026 css colums    */}
                <div className="grid-container wow fadeInUp" data-wow-delay="0.6s">
                    {project.map((photo, index) => {
                        return <div key={index}>
                            <img className='grid-item grid-item-1' src={photo.img} alt="Galeri" />
                        </div>
                    })}
                </div>

            </section>

        </>
    )
}

export default Projectdetails;