import { Link } from "react-router-dom";

function Navbar() {
    var x = 0;
    function demo() {
        x++;
        if (x > 1) {
            x = 0;
        }
        if (x == 1) {
            document.getElementById('toggle').style.left = '0px';
        }
        else {
            document.getElementById('toggle').style.left = '-400px'
        }
    }
    return (
        <>
            <aside className="side-widget" id="toggle" >
                <div className="inner">
                    {/* <!-- Logo Menu Mobile --> */}
                    <div className="logo">
                        {/* <a href="index.html"><img src="img/logo.png" alt="Image" /></a> */}
                        <Link to='/' onClick={demo}><img src="/img/logo.png" alt="Image" style={{ height: '70px' }} /></Link>
                    </div>
                    <div className="hide-mobile">
                        <h6 className="widget-title">CONTACT INFO</h6>
                        <address className="address">
                            <p>+1 (234) 567 89 10<br />
                                <a href="#">example@example.com</a>
                            </p>
                        </address>
                    </div>
                    <div className="show-mobile">
                        <div className="site-menu">
                            <ul className="menu">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <Link to='/'><li onClick={demo}><a>Home</a></li></Link>
                                <Link to='/about'><li onClick={demo}><a>About Us</a></li></Link>
                                <Link to='/service'><li onClick={demo}><a>Services</a></li></Link>
                                <Link to='/project'><li onClick={demo}><a>Project</a></li></Link>
                                <Link to='/contact'><li onClick={demo}><a>Contact</a></li></Link>
                            </ul>
                        </div>
                    </div>
                    <small>Shiv Builders</small>
                </div>
            </aside>
            <nav className="navbar">
                <div className="container">
                    {/* <!-- Logo Menu Desktop --> */}
                    <div className="logo">
                        <Link to='/'><img src="/img/logo.png" alt="Image" /></Link>
                    </div>
                    <div className="site-menu">
                        <ul className="menueffect">
                            <li><Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/'>Home</Link></li>
                            <li><Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/about'>About Us</Link></li>
                            <li><Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/service'>Services </Link></li>
                            <li><Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/project'>Project</Link></li>
                            <li><Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/contact'>Contact</Link></li>
                        </ul>
                    </div>
                    <div className="hamburger-menu hamburgur" onClick={demo}> <span></span> <span></span> <span></span> </div>
                </div>
            </nav>

            <div>
                <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className="toparrow"><i class="fa-solid fa-angle-up"></i></button>
            </div>

        </>
    )
}

export default Navbar; 