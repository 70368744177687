import React from "react";
import Slider from "react-slick";
import CountUp from 'react-countup';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, NavLink } from "react-router-dom";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const card = [
    {
        title: 'Shivalay Heights',
        cardimg: '/projects/shivalay heights/sv2.png'
    },
    {
        title: 'Interior Architecture Project',
        cardimg: '/projects/Interioe/living room/lr2.jpeg'
    },
    {
        title: 'Full Fledged Project',
        cardimg: 'img/project2.png'
    },
    {
        title: 'Architectural Project',
        cardimg: '/projects/Architectrual/rgr.jpg'
    },
    {
        title: '360° View',
        cardimg: '/projects/360deg.jpg'
    }
]

function Home() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 350,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    return (
        <>

            <AutoplaySlider
                play={true}
                // cancelOnInteraction={false} // should stop playing on user interaction
                interval={2500}
            >
                <div data-src="../img/slide2.jpg" className="" >
                    <div className="hero-content">
                        <div data-swiper-parallax="300" className="slide-title">
                            <h2>New Generation <br></br> <b>Modern Design</b></h2>
                        </div>
                        <div data-swiper-parallax="400" className="slide-text">
                            <p>We prepare the place of your dreams</p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div data-src="../img/slide1.jpg" className="">
                    <div className="hero-content">
                        <div data-swiper-parallax="300" className="slide-title">
                            <h2>Special Generation <br></br> <b>Design Furniture</b></h2>
                        </div>
                        <div data-swiper-parallax="400" className="slide-text">
                            <p>We create the interior with exclusive premium items.</p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div data-src="../img/slide3.jpg" className="">
                    <div className="hero-content">
                        <div data-swiper-parallax="300" className="slide-title">
                            <h2>Project With <br></br> <b>Special Ideas</b></h2>
                        </div>
                        <div data-swiper-parallax="400" className="slide-text">
                            <p>Special isolation solutions suitable for the area.</p>
                        </div>
                        <div className="clearfix"></div>
                        
                    </div>
                </div>
            </AutoplaySlider>

            {/* <section className="hero-slider hero-style">
                <div className="swiper-container">
                    <div className="swiper-wrapper">

                        <div className="swiper-slide">
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: 'url(img//slide1.jpg)' }}>
                                <div className="container">
                                    <div data-swiper-parallax="300" className="slide-title">
                                        <h2>New Generation Modern Design</h2>
                                    </div>
                                    <div data-swiper-parallax="400" className="slide-text">
                                        <p>We prepare the place of your dreams</p>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div data-swiper-parallax="500" className="slide-btns">
                                        <a href="#" className="theme-btn-s2">Register now</a>
                                        <a href="#" className="theme-btn-s3"><i className="fas fa-chevron-circle-right"></i> Get
                                            Info</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="swiper-pagination"></div>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </div>
            </section> */}

            {/*          Info-top           */}
            <section className="info-top">
                <div className="tabloozellik">
                    <div className="tablo--1-ve-4">
                        <div className="paketler wow flipInY" data-wow-delay="0s" onclick="location.href='#';" style={{ cursor: 'pointer' }}>
                            <div className="hizmet-kutu">
                                <div className="kutu-duzen">
                                    <h3><a href="#"> Creative & Natural </a></h3>
                                    <div className="boslukicon"></div>
                                    <div className="icon-box">
                                        <span className="border-layer"></span>
                                        <i className="flaticon-house"></i>
                                    </div>
                                    <p>We are building a creative living space by working in pursuit of differences and quality.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tablo--1-ve-4">
                        <div className="paketler wow flipInY" data-wow-delay="0.6s" onclick="location.href='#';" style={{ cursor: 'pointer' }}>
                            <div className="hizmet-kutu">
                                <div className="kutu-duzen">
                                    <h3><a href="#"> Innovative & Quality </a></h3>
                                    <div className="boslukicon"></div>
                                    <div className="icon-box">
                                        <span className="border-layer"></span>
                                        <i className="flaticon-care"></i>
                                    </div>
                                    <p>We serve our customers with our expert architects for all architectural consultancy needs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tablo--1-ve-4">
                        <div className="paketler wow flipInY" data-wow-delay="0.7s" onclick="location.href='#';" style={{ cursor: 'pointer' }}>
                            <div className="hizmet-kutu">
                                <div className="kutu-duzen">
                                    <h3><a href="#"> Fast & Reliable </a></h3>
                                    <div className="boslukicon"></div>
                                    <div className="icon-box">
                                        <span className="border-layer"></span>
                                        <i className="flaticon-helmet"></i>
                                    </div>
                                    <p>With our competent experience and expert architects, we deliver your projects as soon as possible.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tablo--1-ve-4">
                        <div className="paketler wow flipInY" data-wow-delay="0.8s" onclick="location.href='#';" style={{ cursor: 'pointer' }}>
                            <div className="hizmet-kutu">
                                <div className="kutu-duzen">
                                    <h3><a href="#"> Professional Service </a></h3>
                                    <div className="boslukicon"></div>
                                    <div className="icon-box">
                                        <span className="border-layer"></span>
                                        <i className="flaticon-review"></i>
                                    </div>
                                    <p>With our expert team, we put different designs into practice and reveal your difference in a quality way.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*        Who Are we          */}
            <section className="services-top">
                <div className="h-yazi-ortalama h-yazi-margin-orta-3">
                    <h2 className="h2-baslik-hizmetler-2 wow fadeInUp" data-wow-delay="0.3s">Who Are We <strong
                        style={{ fontSize: '5rem', color: '#1a1a1a' }}> ?</strong> </h2>
                </div>
                <div className="bosluk3"></div>
                <div className="tablo">
                    <div className="tablo--1-ve-2 wow zoomInStable" data-wow-delay="0.4s">
                        <div className="galeri" data-tilt>
                            <img src="img/interior-design-about.png" alt="interior design about"
                                className="galeri__gorsel galeri__gorsel--3 zimage" />
                        </div>
                    </div>
                    <div className="tablo--1-ve-3 home-detail wow fadeInRight" data-wow-delay="0.5s">
                        <h2 className="h2-baslik-anasayfa-ozel1 wow fadeInRight" data-wow-delay="0.6s">New Generation
                            <strong><br />Modern Designs</strong> </h2>
                        <div className="bosluk333"></div>
                        <p className="paragraf wow fadeInRight" data-wow-delay="0.7s">
                            We are in time and we catch what the age brings by listening to the sound of time.
                            With our work through time, we produce long-term projects and realize the dreamed spaces.
                        </p>
                        <div className="bosluk333"></div>
                        <img className="divider" width="120" height="15" title="divider" alt="divider" src="img/divider.jpg" />
                        <div className="bosluk333"></div>
                        <h2 className="h2-baslik-anasayfa-ozel1 wow fadeInRight" data-wow-delay="0.8s">Trending
                            <strong>Designs</strong></h2>
                        <div className="bosluk333"></div>
                        <p className="paragraf wow fadeInRight" data-wow-delay="0.9s">
                            We are building long-term, new places in our project and application works that we started
                            with the understanding of quality service. We use state-of-the-art products with state-of-the-art
                            methods and mediate our customers to live in more comfortable, more comfortable and more stylish
                            spaces.
                        </p>
                        <div className="bosluk333"></div>
                        <NavLink onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to={'/about'} className="custom-button wow fadeInRight" data-wow-delay="1s"> Get to know us →</NavLink>
                        <div className="bosluk3rh"></div>
                    </div>
                </div>
            </section>

            {/*       What Are We Doing        */}
            <section className="services1 wow fadeInUp" data-wow-delay="0.3s">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="h-yazi-ortalama h-yazi-margin-orta-3">
                                <h2 className="h2-baslik-hizmetler-21 wow fadeInUp" data-wow-delay="0.4s"> What Are We Doing <strong
                                    style={{ fontSize: '5rem', color: '#ffffff' }}>?</strong> </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bosluk3"></div>
                <div className="tabloservices">
                    <div className="tablo--1-ve-3">
                        <div className="paketler2 wow rollIn" data-wow-delay="0.5s">
                            <div className="paketler2__on paketler2__on--onyazi">
                                <div className="paketler2__gorsel paketler2__gorsel--1">
                                    <div className="paketler2__icerik">
                                        <div className="iconw"><i className="flaticon-call-center"></i></div>
                                        <h3 className="baslik-3white h-yazi-margin-kucuk">Architectural Consulting</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            We provide services with our experienced architects who are experts in their fields
                                            for architectural consultancy needs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="paketler2__on paketler2__on--arkayazi paketler2__on--arkayazi-1">
                                <div className="paketler2__pr">
                                    <div className="paketler2__pr-kutu">
                                        <h3 className="baslik-sol h-yazi-margin-kucuk">Architectural Consulting</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            We provide services with our experienced architects who are experts in their fields
                                            for architectural consultancy needs.
                                        </p>
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/service' className="buton buton--beyaz">Review &rarr;</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--Service 2--> */}
                    <div className="tablo--1-ve-3 wow fade">
                        <div className="paketler2 wow rollIn" data-wow-delay="0.6s">
                            <div className="paketler2__on paketler2__on--onyazi">
                                <div className="paketler2__gorsel paketler2__gorsel--2">
                                    <div className="paketler2__icerik">
                                        <div className="iconw"><i className="flaticon-helmet"></i></div>
                                        <h3 className="baslik-3white h-yazi-margin-kucuk">Interior Architecture Project</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            We offer the highest quality and most functional architectural services to our
                                            customers in interior design services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="paketler2__on paketler2__on--arkayazi paketler2__on--arkayazi-2">
                                <div className="paketler2__pr">
                                    <div className="paketler2__pr-kutu">
                                        <h3 className="baslik-sol h-yazi-margin-kucuk">Interior Architecture Project</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            We offer the highest quality and most functional architectural services to our
                                            customers in interior design services.
                                        </p>
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/service' className="buton buton--beyaz">Review &rarr;</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--Service 3--> */}
                    <div className="tablo--1-ve-3 wow fade">
                        <div className="paketler2 wow rollIn" data-wow-delay="0.7s">
                            <div className="paketler2__on paketler2__on--onyazi">
                                <div className="paketler2__gorsel paketler2__gorsel--3">
                                    <div className="paketler2__icerik">
                                        <div className="iconw"><i className="flaticon-house"></i></div>
                                        <h3 className="baslik-3white h-yazi-margin-kucuk">Architectural Project</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            We produce projects with our innovative ideas and unique perspective in
                                            architectural projects.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="paketler2__on paketler2__on--arkayazi paketler2__on--arkayazi-3">
                                <div className="paketler2__pr">
                                    <div className="paketler2__pr-kutu">
                                        <h3 className="baslik-sol h-yazi-margin-kucuk">Architectural Project</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            We produce projects with our innovative ideas and unique perspective in
                                            architectural projects.
                                        </p>
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/service' className="buton buton--beyaz">Review &rarr;</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tabloservices">
                    {/* <!--Service 4--> */}
                    <div className="tablo--1-ve-3 wow fade">
                        <div className="bosluk3h"></div>
                        <div className="paketler2 wow rollIn" data-wow-delay="0.5s">
                            <div className="paketler2__on paketler2__on--onyazi">
                                <div className="paketler2__gorsel paketler2__gorsel--4">
                                    <div className="paketler2__icerik">
                                        <div className="iconw"><i className="flaticon-truck"></i></div>
                                        <h3 className="baslik-3white h-yazi-margin-kucuk">Renovation Decoration</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            Repairing or renewing the existing decoration works in existing spaces is as
                                            important as creating a new space.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="paketler2__on paketler2__on--arkayazi paketler2__on--arkayazi-4">
                                <div className="paketler2__pr">
                                    <div className="paketler2__pr-kutu">
                                        <h3 className="baslik-sol h-yazi-margin-kucuk">Renovation Decoration</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            Repairing or renewing the existing decoration works in existing spaces is as
                                            important as creating a new space.
                                        </p>
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/service' className="buton buton--beyaz">Review &rarr;</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--Service 5--> */}
                    <div className="tablo--1-ve-3 wow fade">
                        <div className="bosluk3h"></div>
                        <div className="paketler2 wow rollIn" data-wow-delay="0.6s">
                            <div className="paketler2__on paketler2__on--onyazi">
                                <div className="paketler2__gorsel paketler2__gorsel--5">
                                    <div className="paketler2__icerik">
                                        <div className="iconw"><i className="flaticon-computer"></i></div>
                                        <h3 className="baslik-3white h-yazi-margin-kucuk">Furniture Design</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            In the field of furniture design, we create special living spaces for our customers
                                            with original and unique designs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="paketler2__on paketler2__on--arkayazi paketler2__on--arkayazi-5">
                                <div className="paketler2__pr">
                                    <div className="paketler2__pr-kutu">
                                        <h3 className="baslik-sol h-yazi-margin-kucuk">Furniture Design</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            In the field of furniture design, we create special living spaces for our customers
                                            with original and unique designs.
                                        </p>
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/service' className="buton buton--beyaz">Review &rarr;</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--Service 6--> */}
                    <div className="tablo--1-ve-3 wow fade">
                        <div className="bosluk3h"></div>
                        <div className="paketler2 wow rollIn" data-wow-delay="0.7s">
                            <div className="paketler2__on paketler2__on--onyazi">
                                <div className="paketler2__gorsel paketler2__gorsel--6">
                                    <div className="paketler2__icerik">
                                        <div className="iconw"><i className="flaticon-insurance"></i></div>
                                        <h3 className="baslik-3white h-yazi-margin-kucuk">Projecting</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            With our expert team, we put different designs into practice and reveal your
                                            difference in a quality way.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="paketler2__on paketler2__on--arkayazi paketler2__on--arkayazi-6">
                                <div className="paketler2__pr">
                                    <div className="paketler2__pr-kutu">
                                        <h3 className="baslik-sol h-yazi-margin-kucuk">Projecting</h3>
                                        <p className="services-kutu2--yazi wow fade">
                                            With our expert team, we put different designs into practice and reveal your
                                            difference in a quality way.
                                        </p>
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/service' className="buton buton--beyaz">Review &rarr;</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*        Project         */}
            <section className="projeler">
                <div className="h-yazi-ortalama h-yazi-margin-orta-3">
                    <h2 className="h2-baslik-hizmetler-2 wow fadeInUp" data-wow-delay="0.3s">What Have We Done <strong
                        style={{ fontSize: '5rem', color: '#1a1a1a' }}> ?</strong> </h2>
                </div>
                <div className="bosluk3"></div>
                <div className="container">
                    <Slider {...settings}>
                        {card.map((detail, index) => {
                            return <div className="swiper-slide" key={index}>
                                <div className="component-systemTabs">
                                    <div id="tab-1" data-tab-title="Architectural Project" className="tab-content current wow fade">
                                        <div className="cards">
                                            <div className="card wow zoomInLeft" data-wow-delay="0.4s"
                                                onclick="window.location.href='project-detail.html'">
                                                <img src={detail.cardimg} alt="Project" />
                                                <div className="cardContent">
                                                    <h2>{detail.title}</h2>
                                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })} to='/project' style={{ textDecoration: 'none' }}><button >View</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </Slider>
                </div>
            </section>

            {/*        Count         */}
            <section className="content-section" data-background="#fafafa" style={{ backgroundColor: 'white' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0.6s">
                            <div className="icon"><i className="flaticon-group"></i></div>
                            <div className="counter-box wow fade">
                                <CountUp className="odometer"
                                    start={0}
                                    end={214}
                                    duration={3}
                                    separator=""
                                    // decimals={4}
                                    decimal=","
                                ></CountUp>
                                <h6>Happy Clients</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInRight" data-wow-delay="0.7s">
                            <div className="icon"><i className="flaticon-badge"></i></div>
                            <div className="counter-box wow fade">
                                <CountUp className="odometer"
                                    start={0}
                                    end={268}
                                    duration={3}
                                    separator=""
                                    // decimals={4}
                                    decimal=","
                                ></CountUp>
                                <h6>Finished Projects</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInRight" data-wow-delay="0.8s">
                            <div className="icon"><i className="flaticon-helmet"></i></div>
                            <div className="counter-box wow fade">
                                <CountUp className="odometer"
                                    start={0}
                                    end={18}
                                    duration={5}
                                    separator=""
                                // decimals={4}
                                ></CountUp>
                                <h6>Ongoing Projects</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* what Did Thay sau */}

        </>
    )
}

export default Home;