
import React from "react";
import Pagehead from "./Pagehead";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../css/service.css'


function Service() {
    const data = [
        {
            img: 'img/services1.png',
            title: 'Architectural Consulting',
            description: 'We serve our customers with our expert and competent architects for all architectural consultancy needs. We are at your service for innovative, economical, stylish and artistic architectural solutions. We are an architectural firm that pursues differences and pursues quality. In our company, which carries out its projects and works in all areas belonging to the architectural sector, we deal with every detail from A to Z and give the most accurate answer to the expectations of our customers. With our architectural consultancy service, we show our difference from other companies in the sector.'
        },
        {
            img: 'img/services2.png',
            title: 'Interior Architecture Project',
            description: 'Within the scope of interior architect consultancy services, we offer our customers the highest quality, most original and most functional architectural services along with quality materials. We can create differences in your interiors, add spirit to your living spaces and bring the lines that reflect your personality to life. Arranging the interior of a building includes choosing the most suitable furniture for that area, if there is no ready-made furniture suitable for that area, creating it from scratch and choosing the most suitable accessories for this area. In addition, the color and texture of the area to be interior design is also within the scope of interior architecture studies. For this reason, a firm that is competent to carry out all these activities should be preferred for a professional interior architecture service.'
        },
        {
            img: 'img/services3.png',
            title: 'Architectural Project',
            description: 'We are at your service for your eye-catching, magnificent, innovative, functional and artistic architectural projects. We are taking firm steps forward in the architectural sector in which we operate with the principle of artistic and original projects, together with our understanding of quality service. In our architectural firm, which operates with our original works and extraordinary projects, we maintain quality from the design phase to the end of the implementation phase, and we play an active role in the emergence of long-term, contemporary structures.'
        },
        {
            img: 'img/services4.png',
            title: 'Renovation Decoration',
            description: 'Repairing or renewing the existing decoration works in existing spaces is as important as creating a new space. In fact, renovation decoration works are often much more difficult than building a new space. For this reason, the professionalism of the firm and the firm that will be preferred in decoration modifications are of great importance. At this point, we offer our customers the most professional service and the most accurate solutions.'
        },
        {
            img: 'img/services5.png',
            title: 'Furniture Design',
            description: 'We provide original and unique services to our customers in the field of furniture design. If you are specific to your living spaces; If you want to gain different, unusual and distinctive furniture, contact us now. Furniture design is a process of creating a new furniture, carried out in line with the wishes and expectations of the customers, with the aim of providing comfort as well as being functional. It is very important to stay original and to implement new ideas in the most successful way in the creation process, in which concrete data are obtained from an abstract idea. In this context; You can get support from our architects for the most functional, the most stylish, the most compatible with living spaces and the original furniture.'
        },
        {
            img: 'img/services6.jpg',
            title: 'Projecting',
            description: 'When you meet with your architect and provide the data requested from you about your project, you will be presented with a price offer with the details of the project service as soon as possible.'
        }
    ]

    const tabcss = {
        width: '100%',
        boxShadow: 'initial',
    }

    return (
        <>

            <Pagehead headtitle="Services" page="Services" />



            <section className="hizmetlerr-bolumu">
                <div className="h-yazi-ozel h-yazi-margin-ozel">
                </div>
                <div className="container">

                    <Tabs >
                        <div className="row">
                            <div className="col servi-mid">
                                <div className="sidebar-service wow fadeInLeft" data-wow-delay="0.8s">
                                    <TabList>
                                        <Tab style={tabcss}><span className="menu-service" >Architectural Consulting</span></Tab>
                                        <Tab style={tabcss}><span className="menu-service" >Interior Architecture</span></Tab>
                                        <Tab style={tabcss}><span className="menu-service" >Architectural Project</span></Tab>
                                        <Tab style={tabcss}><span className="menu-service" >Renovation Decoration</span></Tab>
                                        <Tab style={tabcss}><span className="menu-service" >Furniture Design</span></Tab>
                                        <Tab style={tabcss}><span className="menu-service" >Projecting</span></Tab>
                                    </TabList>
                                </div>
                            </div>

                            <div className="col-lg-8 servi-mid">
                                {data.map((card, index) => {
                                    return <TabPanel key={index}>
                                        <div className="bosluk333"></div>
                                        <div className="galeri wow fadeInRight" data-wow-delay="0.7s">
                                            <img src={card.img} alt="Architectural Consulting" className="galeri__gorsel galeri__gorsel--3" />
                                        </div>
                                        <div className="bosluk3"></div>
                                        <h2 className="h2-baslik-anasayfa-ozel-services wow fade"> {card.title} </h2>
                                        <div className="bosluk333"></div>
                                        <p className="paragraf wow fadeInRight" data-wow-delay="0.7s">{card.description}</p>
                                        <div className="bosluk333"></div>
                                        <div className="bosluk3"></div>
                                        <div className="tabloozellik">
                                            <div className="tablo--1-ve-2">
                                                <div className="paketler4 wow fadeInUp">
                                                    <div className="paketler4__on paketler4__on--onyazi">
                                                        <div className="paketler4__icerik">
                                                            <div className="icon"><i className="flaticon-medal"></i></div>
                                                            <h3 className="baslik-3 h-yazi-margin-kucuk about-card-head">Reliability</h3>
                                                        </div>
                                                    </div>
                                                    <div className="paketler4__on paketler4__on--arkayazi paketler4__on--arkayazi-1">
                                                        <div className="paketler4__pr">
                                                            <div className="paketler4__pr-kutu">
                                                                <h3 className="baslik-orta h-yazi-margin-kucuk">Reliability</h3>
                                                                <p className="services-kutu2--yazi1 wow fade">
                                                                    We provide accurate, reliable and ethical services with our expert staff.
                                                                    We apply the fastest and most reliable methods for your brand.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tablo--1-ve-2">
                                                <div className="paketler4 wow fadeInUp">
                                                    <div className="paketler4__on paketler4__on--onyazi">
                                                        <div className="paketler4__icerik">
                                                            <div className="icon"><i className="flaticon-badge"></i></div>
                                                            <h3 className="baslik-3 h-yazi-margin-kucuk  about-card-head">Loyalty</h3>
                                                        </div>
                                                    </div>
                                                    <div className="paketler4__on paketler4__on--arkayazi paketler4__on--arkayazi-2">
                                                        <div className="paketler4__pr">
                                                            <div className="paketler4__pr-kutu">
                                                                <h3 className="baslik-orta h-yazi-margin-kucuk">Loyalty</h3>
                                                                <p className="services-kutu2--yazi1 wow fade">
                                                                    Our long-term work continues until the job is finished.
                                                                    We establish solid and long-term relationships with all the companies we work with.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bosluk333"></div>
                                        <div className="bosluk3"></div>
                                    </TabPanel>
                                })}
                            </div>
                        </div>
                    </Tabs>

                </div>
            </section>
        </>
    )
}

export default Service;