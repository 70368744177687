

import { Route, Routes } from 'react-router-dom';
import Navbar from './componant/Navbar';
import Home from './componant/Home';
import Footer from './componant/Footer';
import About from './componant/About';
import Project from './componant/Project';
import Service from './componant/Service';
import Contact from './componant/Contact';
import Projectdetails from './componant/Projectdetails';
import Projectdetailsarch from './componant/Projectdetailsarch';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/project' element={<Project />} />
        <Route path='/service' element={<Service />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/prjectdetails/:id' element={<Projectdetails />} />
        <Route path='/projectdetailsach/:id' element={<Projectdetailsarch />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

